
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: transparent;

}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: var(--primary-color);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(0, 0, 0, 0.2);
}

.p-galleria .p-galleria-item-nav:not(.p-disabled) {
    color: var(--primary-color);
}

.p-galleria .p-galleria-item-nav:hover {
    color: var(--primary-color-hover);
}
