.noepack-wrapper {
    display: none;
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.noepack-modal {
    max-width: 1000px;
    margin: 50px auto;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    box-shadow: 0 0 1rem #999;
}

.noepack-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.noepack-close {
    padding: .25rem .5rem;
    /*margin: -.5rem -.5rem -.5rem auto;*/
    box-sizing: content-box;
    border: none;
    border-radius: .25rem;
    color: #fff;
    background-color: #e83a3a;
}

.noepack-title {
    padding: 0;
    margin: 0;
    line-height: 1.5;
    font-size: 1.25rem;
    font-weight: 500;
}

.noepack-body {
    padding: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

/*.noepack-search,*/
/*.noepack-branches {*/
/*    width: 400px;*/
/*    box-sizing: border-box;*/
/*}*/

.noepack-map {
    width: 560px;
    height: 420px;
    float: right;
}

.noepack-popup {
    z-index: 1070;
    display: block;
    max-width: 200px;
    font-weight: 400;
    line-height: 1.5;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    padding: 8px;
}

.noepack-footer {
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*flex-shrink: 0;*/
    /*align-items: center;*/
    /*justify-content: flex-end;*/
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.noepack-cancel,
.noepack-ok {
    height: 60px;
    padding: .375rem .75rem;
    font-size: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    margin: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    float: right;
}

.noepack-cancel {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.noepack-cancel:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
}

.noepack-ok {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.noepack-ok:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
}

.noepack-search {
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    margin-bottom: .5rem;
}

.noepack-search:focus {
    outline: none;
}

.noepack-mode {
    display: none;
}

.noepack-mode img {
    height: 22px;
}

.noepack-wrapper.show {
    display: block;
}

.noepack-map.hide,
.noepack-branches.hide{
    display: none;
}

.noepack-list {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 420px;
    float: left;
}

.noepack-selected {
    /*flex-grow: 1;*/
    border: 1px solid #0d6efd;
    color: #0d6efd;
    background-color: #fff;
    border-radius: .25rem;
    padding: .375rem .75rem;
    margin-bottom: .5rem;
}

.noepack-selected button {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-top: 5px;
}

.noepack-selected-branch {
    /*margin-right: 2rem;*/
}

.noepack-selected-openinghours {
    display :none;
    /*margin-right: 2rem;*/
}

.noepack-selected-payment {
    display :none;
}

/*.noepack-selected div {*/
/*    display: inline-block;*/
/*    vertical-align: text-top;*/
/*}*/

/*#noepack-selected.selected {*/
/*    color: #fff;*/
/*    background-color: #0d6efd;*/
/*    border-color: #0d6efd;*/
/*}*/

.noepack-branches {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /*max-height: 366px;*/
    overflow: auto;
    border-radius: .25rem;
}

.noepack-branch {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    cursor: pointer;
    text-align: left;
}

.noepack-branch * {
    pointer-events: none;
}

.noepack-branch:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.noepack-branch:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.noepack-branch + .noepack-branch {
    border-top-width: 0;
}

.noepack-branch:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

@media (max-width: 1024px){
    .noepack-wrapper {
        width: auto;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .noepack-modal {
        height: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    .noepack-body {
        padding: 0;
        height: calc(100% - 50px);
        /*height: 100%;*/
        position: relative;
    }

    .noepack-header {
        padding: .5rem .5rem;
    }

    .noepack-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .noepack-branches {
    /*    width: auto;*/
    /*    height: auto;*/
    /*    max-height: none;*/
    /*    position: absolute;*/
    /*    top: 3rem;*/
    /*    left: .5rem;*/
    /*    right: .5rem;*/
    /*    bottom: .5rem;*/
    }

    .noepack-list {
        height: auto;
        max-height: 85%;
        width: auto;
        position: absolute;
        top: .5rem;
        left: .5rem;
        right: .5rem;
        /*bottom: .5rem;*/
        z-index: 1;
    }

    .noepack-map {
        width: 100%;
        height: 100%;
    }

    .noepack-map canvas {
        max-width: initial;
    }

    /*#noepack-mode {*/
    /*    width: 40px;*/
    /*    height: 38px;*/
    /*    vertical-align: top;*/
    /*    margin-bottom: 1rem;*/
    /*    background-color: #fff;*/
    /*    border: 1px solid #ced4da;*/
    /*    border-radius: .25rem;*/
    /*    cursor: pointer;*/
    /*    display: inline-block;*/
    /*}*/

    /*.noepack-search {*/
    /*    width: auto;*/
    /*    position: absolute;*/
    /*    top: .5rem;*/
    /*    left: .5rem;*/
    /*    right: .5rem;*/
    /*    z-index: 1;*/
    /*}*/

    .noepack-selected {
        min-width: auto;
        width: auto;
        float: none;
    }

    .noepack-ok {
        width: 100%;
        height: auto;
        float: none;
    }

    .ol-zoom {
        top: inherit;
        left: .5em;
        bottom: .5em;
        right: inherit;
    }
}


.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */