
.ui-expandable-inverted {
    position: relative;
    overflow: hidden;
    transition: height 0.6s ease;

    &:after,
    &:before {
        height: 64px;
        content: "";
        width: 100%;
        position: absolute;
        z-index: 10;
        transition: 250ms;
        pointer-events: none;
    }

    &:after {
        background: linear-gradient(to bottom, transparent, #fbf9f7);
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.open:after {
        height: 0;
    }
}
